<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-col cols="12" xl="12">
			<transition name="slide">
				<b-card no-body>
					<b-card-header>
						<b-row>
							<b-col cols="6">
								<h4>{{ rendTitle }}</h4>
							</b-col>
							<b-col cols="6" class="pull-right">
								<b-button
									:style="`${!$screen.md ? 'width:100%' : ''}`"
									@click="backToTaskList"
									variant="success"
									size="md"
									class="btn btn-primary btn-md float-right"
								>
									{{ this.FormMSG(15, 'Back to tasks') }}&nbsp;<i class="icon-action-undo"></i>
								</b-button>
							</b-col>
						</b-row>
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col cols="6" class="my-1">
								<b-form-group
									:label="FormMSG(2, 'Filter')"
									label-cols="3"
									label-align-sm="left"
									label-size="sm"
									label-for="filterInput"
									class="mb-0"
									v-if="$screen.width >= 992"
								>
									<b-input-group size="sm">
										<b-form-input
											v-model="filter"
											type="search"
											id="filterInput"
											:placeholder="this.FormMSG(3, 'Type to Search')"
										></b-form-input>
										<b-input-group-append>
											<b-button :disabled="!filter" @click="filter = ''">
												<i class="fa fa-times" aria-hidden="true"></i>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group>
									<b-button :block="!$screen.md" @click="newTaskType" variant="success" size="md" class="btn btn-primary btn-md float-right">
										{{ this.FormMSG(4, 'New Task Type') }}
									</b-button>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<card-list-builder v-if="$screen.width < 992" :items="taskTypes" :fields="taskTypeMobileFields">
									<template slot="actions" slot-scope="data">
										<div v-if="data.item.isSystem || data.item.picture !== ''">
											<img :src="getImage(data.item)" class="task-type-image" alt="photo" style="width: 26px; height: 26px" />
										</div>
										<div v-if="data.item.description.length > 0">
											<b-button
												:id="`task-type-${parseInt(data.item.id, 10)}`"
												size="sm"
												variant="light"
												style="font-size: 1.5rem; line-height: 20px"
											>
												<i class="fa fa-list" aria-hidden="true" style="color: rgba(0, 0, 0, 0.425)"></i>
											</b-button>
											<b-popover :target="`task-type-${parseInt(data.item.id, 10)}`" triggers="hover" placement="top">
												<template #title>{{ FormMSG(21, 'Description') }}</template>
												{{ data.item.description }}
											</b-popover>
										</div>
										<b-button variant="primary" size="sm" @click="editType(data.item)">
											<i class="icon-pencil"></i>
										</b-button>
										<b-button variant="danger" size="sm" @click="deleteType(data.item.id)" v-if="!data.item.isSystem">
											<i class="icon-trash"></i>
										</b-button>
									</template>
								</card-list-builder>
								<b-table
									v-if="$screen.width >= 992"
									sticky-header="700px"
									ref="taskTypeTable"
									:hover="hover"
									:items="taskTypes"
									:fields="taskTypeFields"
									:current-page="currentPage"
									:per-page="perPage"
									:head-variant="hv"
									:filter="filter"
									responsive="sm"
									bordered
									small
									:empty-text="FormMSG(14, 'No type found')"
									show-empty
								>
									<template v-slot:cell(description)="data">
										<div
											v-if="data.item.description.length > 0"
											v-b-popover.hover.right="`${data.item.description}`"
											:title="FormMSG(21, 'Description')"
										>
											{{ truncate(data.item.description, 15) }}
										</div>
									</template>
									<template v-slot:cell(picture)="data">
										<div v-if="data.item.isSystem || data.item.picture !== ''">
											<img :src="getImage(data.item)" class="task-type-image" alt="photo" />
										</div>
									</template>
									<template v-slot:cell(edit)="data">
										<b-button variant="primary" size="sm" @click="editType(data.item)" class="btn bg-transparent border-0">
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(delete)="data">
										<b-button
											variant="danger"
											size="sm"
											@click="deleteType(data.item.id)"
											v-if="!data.item.isSystem"
											class="btn bg-transparent border-0"
										>
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
								</b-table>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</transition>
		</b-col>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { addUpdateTaskType, getTaskTypes, deleteTaskType } from '@/cruds/tasktypes.crud';
import { mapFilters } from '~filters';

export default {
	name: 'TaskTypes',
	mixins: [languageMessages, GlobalMixin],
	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			filter: '',
			perPage: 0,
			currentPage: 1,
			hover: true,
			taskType: {},
			taskTypes: []
		};
	},
	computed: {
		rendTitle() {
			return this.FormMSG(1, 'Task types');
		},
		priorities() {
			return this.FormMenu(1123);
		},
		baseFields() {
			return [
				{
					key: 'id',
					label: '#ID',
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(5, 'Name'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(20, 'Description'),
					sortable: true
				},
				{
					key: 'picture',
					label: this.FormMSG(6, 'Picture'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'dayDuration',
					label: this.FormMSG(13, 'Nb Days'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'duration',
					label: this.FormMSG(7, 'Duration'),
					formatter: (value) => {
						return value.split('T')[1].substring(0, 5).replace(/[:]/, 'h');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'priority',
					label: this.FormMSG(8, 'Priority'),
					sortable: false,
					formatter: (value) => {
						const findIndex = this.priorities.findIndex((s) => s.value === value);
						return this.priorities[findIndex].text;
					},
					class: 'text-center'
				},
				{
					key: 'totalItem',
					label: this.FormMSG(16, 'Nb Materials'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'edit',
					label: this.FormMSG(9, 'Edit'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				},
				{
					key: 'delete',
					label: this.FormMSG(10, 'Remove'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				}
			];
		},
		taskTypeFields() {
			return this.baseFields;
		},
		taskTypeMobileFields() {
			const keys = ['id', 'description', 'picture', 'edit', 'delete'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		}
	},
	async mounted() {
		await this.reloadData();
	},
	methods: {
		...mapFilters(['truncate']),
		async newTaskType() {
			await addUpdateTaskType(0, {
				name: this.FormMSG(19, 'Default task type')
			}).then(async (record) => {
				this.$router.push('task-types/' + parseInt(record.id, 10));
			});
		},
		editType(item) {
			// console.log('editType: ', item);
			this.$router.push('task-types/' + parseInt(item.id, 10));
		},
		async deleteType(id) {
			let action = async () => {
				let result = await deleteTaskType(id).then((res) => {
					if (res === true || typeof res === 'string') {
						if (res === true) {
							this.taskTypes.splice(
								this.taskTypes.findIndex((taskType) => taskType.id === id),
								1
							);

							this.$toast({
								message: this.FormMSG(11, 'Task type removed successfully'),
								title: this.FormMSG(12, 'Success'),
								variant: 'success'
							});
						} else {
							this.$toast({
								message: this.FormMSG(18, res),
								title: this.FormMSG(17, 'Warning'),
								variant: 'warning'
							});
						}
					}
				});
				return result;
			};
			this.confirmModal(action, this.FormMSG(22, 'Are you sure ?'));
		},
		async reloadData() {
			await getTaskTypes().then((records) => {
				if (records) {
					this.taskTypes = records;
				}
			});
		},
		getImage(item) {
			let urlImg = '';
			if (item.isSystem) {
				try {
					urlImg = process.env.VUE_APP_GQL + '/images/tts-agency-' + item.value;
				} catch (e) {}

				return urlImg;
			} else {
				try {
					urlImg = process.env.VUE_APP_GQL + '/images/' + item.picture;
				} catch (e) {}

				return urlImg;
			}
		},
		backToTaskList() {
			this.$router.push('/tasks');
		}
	}
};
</script>
